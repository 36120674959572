import React from "react";
import {ARTICLE_8} from "shared/Types/articles";
import {useArticlesData} from "shared/Hooks/Articles/useArticlesData";
import SEO from "components/seo";

import {Author, Hero, Image, Layout, List, Text} from "components/BlogLayout";

const Article_8 = () => {
  const data = useArticlesData(ARTICLE_8);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} image={data.image} />
      <Hero data={data}/>
      <Text data={data.text.first}/>
      <Image data={data.images.first}/>
      <Text data={data.text.third}/>
      <Image data={data.images.second}/>
      <Text data={data.text.eighth}/>
      <List data={data.list.first}/>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_8;
